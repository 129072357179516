@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "League Spartan", sans-serif;
}

body {
  overflow-x: hidden;
}

.image {
  width: 50vmin;
  height: 50vmin;
  object-position: center;
  background-color: white;
}
